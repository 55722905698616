import LoaderCSS from "./Loader.module.css";

const Loader = ({ small, background }) => {
  return (
    <>
      <div
        className={`fixed top-0 left-0 flex items-center justify-center w-full h-full z-[100] ${
          background ? `bg-${background}` : "bg-white"
        }`}
      >
        <div
          className={`${LoaderCSS.loader} after:opacity-0 ${
            small
              ? "after:w-16 after:h-16 before:w-16 before:h-16 w-16 h-16"
              : "after:w-32 after:h-32 before:w-32 before:h-32 w-32 h-32"
          } inline-block relative`}
        ></div>
      </div>
    </>
  );
};

export default Loader;
